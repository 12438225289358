var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      this.$route.name != "embed"
        ? _c(
            "nav",
            {
              staticClass: "navbar",
              attrs: {
                id: "nav",
                role: "navigation",
                "aria-label": "main navigation",
              },
            },
            [
              _c("div", { staticClass: "navbar-brand" }, [
                _c("img", {
                  staticClass: "navlogo",
                  attrs: { src: "/img/gobo-logo-black.svg", alt: "" },
                }),
                _c(
                  "a",
                  {
                    staticClass: "navbar-burger",
                    class: { "is-active": _vm.burgerToggle },
                    attrs: {
                      role: "button",
                      "aria-label": "menu",
                      "aria-expanded": "false",
                    },
                    on: {
                      click: function ($event) {
                        _vm.burgerToggle = !_vm.burgerToggle
                      },
                    },
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }),
                    _c("span", { attrs: { "aria-hidden": "true" } }),
                    _c("span", { attrs: { "aria-hidden": "true" } }),
                  ]
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "navbar-menu",
                  class: { "is-active": _vm.burgerToggle },
                },
                [
                  _c(
                    "div",
                    { staticClass: "navbar-item", attrs: { id: "center" } },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "navbar-item",
                          attrs: { href: "https://www.gobo.show/about-us" },
                        },
                        [_vm._v("Our Story")]
                      ),
                      _c(
                        "router-link",
                        { staticClass: "navbar-item", attrs: { to: "/help" } },
                        [_vm._v("Help")]
                      ),
                      _vm.profile
                        ? _c(
                            "router-link",
                            {
                              staticClass: "navbar-item",
                              attrs: { to: "/pass" },
                            },
                            [_vm._v("View My Passes")]
                          )
                        : _vm._e(),
                      _vm.profile
                        ? _c(
                            "a",
                            {
                              staticClass: "navbar-item",
                              on: {
                                click: function ($event) {
                                  _vm.logout = true
                                },
                              },
                            },
                            [_vm._v(" Sign Out ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "navbar-end" }, [
                    _vm.profile
                      ? _c(
                          "div",
                          { staticClass: "navbar-item is-hidden-mobile" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "navbar-item has-dropdown is-hoverable",
                              },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "navbar-link",
                                    attrs: { to: "/pass" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.profile.firstName) +
                                        " " +
                                        _vm._s(_vm.profile.lastName)
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "navbar-dropdown is-boxed" },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "navbar-item",
                                        attrs: { to: "/pass" },
                                      },
                                      [_vm._v("View My Passes")]
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "navbar-item",
                                        on: {
                                          click: function ($event) {
                                            _vm.logout = true
                                          },
                                        },
                                      },
                                      [_vm._v(" Sign Out ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
      _c("router-view", {
        on: {
          sellTickets: _vm.sellTickets,
          sellPasses: _vm.sellPasses,
          sellSeriesPasses: _vm.sellSeriesPasses,
          addWaitlist: _vm.addWaitlist,
        },
      }),
      _c(
        "div",
        {
          staticClass: "modal",
          class: {
            "is-active":
              _vm.buyTickets ||
              _vm.buyPasses ||
              _vm.buySeriesPasses ||
              _vm.logout ||
              _vm.waitlist,
          },
        },
        [
          _vm._v(" > "),
          _c("div", { staticClass: "modal-background is-hidden-mobile" }),
          _c("div", { staticClass: "modal-content" }, [
            _c(
              "div",
              { staticClass: "box", attrs: { id: "modalwrapper" } },
              [
                _vm.waitlist || _vm.logout
                  ? _c("div", { attrs: { id: "modalheader" } }, [
                      _vm.waitlist || !_vm.invitationId
                        ? _c(
                            "a",
                            {
                              attrs: { id: "modalclose" },
                              on: {
                                click: function ($event) {
                                  return _vm.canceled()
                                },
                              },
                            },
                            [_c("i", { staticClass: "fas fa-times" })]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm.buyTickets
                  ? _c("BuyTickets", {
                      attrs: { visible: _vm.buyTickets },
                      on: { canceled: _vm.canceled },
                    })
                  : _vm._e(),
                _vm.buyPasses
                  ? _c("BuyPasses", {
                      attrs: { visible: _vm.buyPasses },
                      on: { canceled: _vm.canceled },
                    })
                  : _vm._e(),
                _vm.buySeriesPasses
                  ? _c("BuySeriesPasses", {
                      attrs: { visible: _vm.buySeriesPasses },
                      on: { canceled: _vm.canceled },
                    })
                  : _vm._e(),
                _vm.logout || _vm.waitlist
                  ? _c(
                      "div",
                      { staticClass: "modal-inner-wrapper" },
                      [
                        _vm.logout
                          ? _c("LogOut", {
                              on: {
                                canceled: _vm.canceled,
                                loggedout: _vm.loggedout,
                              },
                            })
                          : _vm._e(),
                        _vm.waitlist
                          ? _c("Waitlist", { on: { canceled: _vm.canceled } })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      ),
      this.$route.name != "embed"
        ? _c("footer", { staticClass: "footer" }, [
            _vm._m(0),
            _vm._m(1),
            _c("nav", { staticClass: "level is-mobile is-hidden-tablet" }, [
              _vm._m(2),
              _c("div", { staticClass: "level-right" }, [
                _c("div", { attrs: { id: "copy" } }, [
                  _vm._v(
                    "©" + _vm._s(new Date().getFullYear()) + " Gobo Show, Inc"
                  ),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "content has-text-centered is-hidden-mobile" },
              [
                _c("div", { attrs: { id: "spacer" } }),
                _c(
                  "nav",
                  {
                    staticClass: "navbar",
                    attrs: {
                      id: "footer-nav",
                      role: "navigation",
                      "aria-label": "secondary navigation",
                    },
                  },
                  [
                    _c("div", { staticClass: "navbar-brand" }, [
                      _c("div", { attrs: { id: "copy" } }, [
                        _vm._v(
                          "©" +
                            _vm._s(new Date().getFullYear()) +
                            " Gobo Show, Inc"
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "navbar-item is-hidden-mobile",
                        attrs: { id: "center" },
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "navbar-item",
                            attrs: { href: "https://www.gobo.show/about-us" },
                          },
                          [_vm._v("Our Story")]
                        ),
                        _c(
                          "router-link",
                          {
                            staticClass: "navbar-item",
                            attrs: { to: "/help" },
                          },
                          [_vm._v("Help")]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "navbar-item",
                            attrs: {
                              href: "https://www.gobo.show/privacy-notice",
                            },
                          },
                          [_vm._v("Privacy Notice")]
                        ),
                        false
                          ? _c(
                              "router-link",
                              {
                                staticClass: "navbar-item",
                                attrs: { to: "/about" },
                              },
                              [_vm._v("Terms of Service")]
                            )
                          : _vm._e(),
                        _vm._m(3),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "nav",
      {
        staticClass: "navbar is-hidden-mobile",
        attrs: {
          id: "footer-nav-top",
          role: "navigation",
          "aria-label": "secondary navigation",
        },
      },
      [
        _c("div", { staticClass: "navbar-brand" }, [
          _c("img", {
            staticClass: "navlogo",
            attrs: { src: "/img/gobo-logo-white.svg", alt: "Gobo" },
          }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "social is-hidden-tablet" }, [
      _c("a", { attrs: { href: "https://twitter.com/goboshow/" } }, [
        _c("span", { staticClass: "icon is-large" }, [
          _c("i", { staticClass: "fab fa-2x fa-twitter" }),
        ]),
      ]),
      _c("a", { attrs: { href: "https://www.facebook.com/Goboshow/" } }, [
        _c("span", { staticClass: "icon is-large" }, [
          _c("i", { staticClass: "fab fa-2x fa-facebook-f" }),
        ]),
      ]),
      _c("a", { attrs: { href: "https://www.instagram.com/goboshow/" } }, [
        _c("span", { staticClass: "icon is-large" }, [
          _c("i", { staticClass: "fab fa-2x fa-instagram" }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "level-left" }, [
      _c("img", {
        staticClass: "navlogo",
        attrs: { src: "/img/gobo-logo-white.svg", alt: "Gobo" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "navbar-end" }, [
      _c("div", { staticClass: "navbar-item" }, [
        _c("a", { attrs: { href: "https://twitter.com/goboshow" } }, [
          _c("img", {
            staticClass: "socialicon",
            attrs: {
              src: "/img/icons/Twitter.svg",
              alt: "Follow us on Twitter",
            },
          }),
        ]),
      ]),
      _c("div", { staticClass: "navbar-item" }, [
        _c("a", { attrs: { href: "https://www.facebook.com/Goboshow/" } }, [
          _c("img", {
            staticClass: "socialicon",
            attrs: {
              src: "/img/icons/Facebook.svg",
              alt: "Follow us on Facebook",
            },
          }),
        ]),
      ]),
      _c("div", { staticClass: "navbar-item" }, [
        _c("a", { attrs: { href: "https://www.instagram.com/Goboshow/" } }, [
          _c("img", {
            staticClass: "socialicon",
            attrs: {
              src: "/img/icons/Instagram.svg",
              alt: "Follow us on Instagram",
            },
          }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }