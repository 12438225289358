import Vue from "vue";
import Vuex from "vuex";

import * as events from "@/store/events.js";
import * as venues from "@/store/venues.js";
import * as cart from "@/store/cart.js";
import * as artist from "@/store/artist.js";
import * as festival from "@/store/festival.js";
import * as pass from "@/store/pass.js";
import * as series from "@/store/series.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    events,
    venues,
    cart,
    artist,
    festival,
    pass,
    series
  },
});
