import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/help",
    name: "Help",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Help.vue"),
  },
  {
    path: "/events/event/:id",
    name: "event",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Event.vue"),
  },
  {
    path: "/artist/:artistslug",
    name: "artist",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Artist.vue"),
  },
  {
    path: "/artist/:artistslug/event/:id",
    name: "artist",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Event.vue"),
  },
  {
    path: "/series/:id",
    name: "series",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/EventSeries.vue"),
  },
  {
    path: "/festival/:festivalslug",
    name: "festival",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Festival.vue"),
  },
  {
    path: "/events/embed/:id",
    name: "embed",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/EmbedEvent.vue"),
  },
  {
    path: "/pass/:id?/:email?",
    name: "pass",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Pass.vue"),
  },
  {
    path: "/passManager/:id?",
    name: "passManager",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PassManager.vue"),
  },
  //{ path: '/stations', name:'stations', redirect: { name: 'event', params: { id: 'aZm8GrcYyAQm4oC0esKv' } }}
  { path: "/events/event/aZm8GrcYyAQm4oC0esKv", alias: "/stations" },
  { path: "/events/event/uKAojOv6wodws2v5psbA", alias: "/prorata/topgirls" },
  { path: "/events/event/f4C43ARNR9kDhmWmLDMP", alias: "/puppetcabaret" },
  { path: "/events/event/KbB759xiN5HPEdUyrrNK", alias: "/crankiefest" },
  { path: "/events/event/8yWQoo8wLeQXKqx6hcjf", alias: "/46ladies" },
  { path: "/events/event/vSeUnmFQG5owhgBDECrX", alias: "/rabbitplay" },
  { path: "/events/event/HTrX8SWdX5jxdD8OiIoT", alias: "/countup" },
  { path: "/events/event/MW2Wjl48Cmch1ntCGAyl", alias: "/artshapingchangevirtual" },
  { path: "/events/event/FGoPTK1UKlNzZVz0ASPZ", alias: "/artshapingchangeinperson" },
  { path: "/events/event/tsOwAlW52U6u4w7pmexR", alias: "/greenwood" },
  { path: "/series/dSLZsd2SVvrr0RU8gaZz", alias: "/rage&reset" },
  {
    path: "/events/event/441hxeWtNH1fe0ra9gBz",
    alias: "/freshwater/bucketlist",
  },
  {
    path: "/events/event/58DGKn4VZ3ZJvd0dZUbh",
    alias: "/nimbus/theredandthebright",
  },
  {
    path: "/events/event/sRgNnsieVWj1JqryMs8U",
    alias: "/moonlit",
  },
  {
    path: "/events/event/eVR48Lfm4KabvoriMLQD",
    alias: "/hobt/impacttheory",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
