import firebase from "firebase/app";
import "firebase/firestore";

export const state = {
  series: null
};

export const mutations = {
  SET_SERIES(state, series) {
    state.series = series;
  },
};

export const actions = {
  async fetchSeries({ commit }, seriesId) {
    var db = firebase.firestore();
    var docRef = db.collection("eventseries").where("id", "==", seriesId);
    var doc = await docRef.get();

    if (!doc.empty) {
      console.log("Found the series.", doc.docs[0]);
      commit("SET_SERIES", doc.docs[0].data());
    } else {
      console.log("Could not find series " + seriesId);
    }
  },
  async fetchSeriesBySlug({ commit }, artistSlug) {
    var db = firebase.firestore();
    var docRef = db.collection("eventseries").where("urlslug", "==", artistSlug);
    var doc = await docRef.get();

    if (!doc.empty) {
      commit("SET_SERIES", doc.docs[0].data());
    } else {
      console.log("Could not find series " + artistSlug);
    }
  },
};

export const getters = {
  series(state) {
    return state.series;
  },
};
