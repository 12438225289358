<template>
  <div>
    <div style="padding-bottom: 50px;">
      <div class="columns is-gapless">
        <div class="column is-one-fifth is-hidden-mobile"></div>
        <div v-if="series" class="column maincont">
          <div class="imagewrap">
            <img :src="series.image" class="event-image" />
          </div>
          <div class="calendar">
            <div class="cal-stripe"></div>
            <EventDateBadge
              v-if="series && series.start"
              :date="series.start.toDate()"
            />
          </div>
          <div class="description indent">
            <div><EventDateline :event="series" /></div>
            <h1>{{ series.title }}</h1>
            <div class="venue-map" v-if="venue">
              <gmap-map
                :center="venue.location"
                :zoom="17"
                mapTypeControl="false"
                style="width: 300px; height: 200px"
                :options="options"
              >
                <gmap-marker
                  :position="venue.location"
                  :clickable="true"
                  :draggable="true"
                  @click="center = venue.location"
                ></gmap-marker>
              </gmap-map>
              <div class="map-legend">
                <h3>{{ venue.name }}</h3>
                <p>{{ venue.fullAddress }}</p>
              </div>
            </div>
            <div v-html="series.description"></div>
            <h3>Multi-Show Passes</h3>
            <div
              v-for="pass in series.passes"
              :key="pass.id"
              class="passclass"
            >
              <h4>{{ pass.name }}</h4>
              <div class="columns">
                <div class="column">{{ pass.description }}</div>
                <div class="column is-one-quarter" v-if="!pass.status || pass.status != 'soldout'">
                  <strong>${{ pass.price }} per pass</strong>
                </div>
                <div class="column is-one-quarter" v-if="pass.status && pass.status == 'soldout'">
                  <strong class="soldout">${{ pass.price }} per pass</strong> SOLD OUT
                </div>
              </div>
            </div>
            <div class="buttons is-right">
              <button
                class="button is-primary is-pulled-right"
                @click="passes()"
              >
                purchase passes
              </button>
            </div>
          </div>
          <div
            class="performances indent"
            v-if="!series.eventType || series.eventType != 'videoOnDemand'"
          >
            <FollowArtist v-if="false" :artist="series"></FollowArtist>
          </div>
          <div class="indent" v-if="events && events.length < 0">
            <h3>Shows</h3>
          </div>
          <div class="event indent" v-for="event in events" :key="event.id">
            <div class="columns">
              <div class="column">
                <div class="eventimagerapper">
                  <div class="imageholder">
                    <img :src="event.image" alt="" class="eventimage" />
                  </div>
                </div>
                <div class="eventTitle">
                  <router-link
                    :to="{ name: 'event', params: { id: event.id } }"
                    >{{ event.title }}</router-link
                  >
                </div>
                <div>{{ event.act }}</div>
                <div class="showblurb" v-html="event.description"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-one-fifth is-hidden-mobile"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import EventDateBadge from "@/components/event-date-badge";
import EventDateline from "@/components/event-dateline";
import FollowArtist from "@/components/follow-artist";

export default {
  name: "series",
  props: ["id"],
  components: {
    EventDateBadge,
    EventDateline,
    FollowArtist,
  },
  data() {
    return {
      options: {
        disableDefaultUI: true,
        styles: [
          {
            featureType: "poi",
            elementType: "labels",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
        ],
        mapId: "a7f1aa6c21b72aae",
      },
    };
  },
  computed: {
    ...mapGetters({
      events: "events",
      series: "series",
      venue: "venue",
    }),
  },
  methods: {
    passes() {
      console.log("herde");
      if (this.series.chargeSalesTax)
        store.dispatch("setTaxRate", this.series.salesTaxRate);
      this.$emit("sellSeriesPasses");
      this.$analytics.logEvent("pass_purchase_started", {
        source: "Primary CTA",
        seriesId: this.series.id,
        seriesTitle: this.series.title,
      });
    },
  },
  async created() {
    console.log("slugo", this.id);
    await this.$store.dispatch("fetchSeries", this.id);
    if (this.series.venue)
      await this.$store.dispatch("fetchVenue", this.series.venue);
    this.$store.dispatch("fetchSeriesEvents", this.series.id);
  },
};
</script>

<style>
.datelist .cal-body {
  border: none;
}
.event {
  clear: both;
  margin-bottom: 10px;
}
.passclass {
  margin-bottom: 30px;
}
.showblurb {
  max-height: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.soldout {
  text-decoration: line-through;
}
.maincont {
  padding-bottom: 100px;
}
</style>
